<template>
  <div v-if="hasilData">
    <span class="icon" :class="hasilClass">
      <fa :icon="hasilIcon"></fa>
    </span>
  </div>
</template>


<script>
const hasilMap = {
  Lulus: { class: "has-text-success", icon: "check" },
  "Tidak Lulus": { class: "has-text-danger", icon: "times" },
  Belum: { class: "has-text-info", icon: "minus" },
  Ya: { class: "has-text-success", icon: "check" },
  Tidak: { class: "has-text-danger", icon: "times" },
};

export default {
  name: "HasilMark",
  props: ["hasil"],
  data() {
    return {
      hasilData: this.hasil
    };
  },
  computed: {
    hasilClass() {
      return hasilMap[this.hasilData].class
    },
    hasilIcon() {
      return hasilMap[this.hasilData].icon
    }
  },
  watch: {
    // When v-model is changed: update internal value
    hasil(value) {
      this.hasilData = value;
    }
  }
};
</script>
